<template>
    <div>
        <!--<section class="contenedor-boton">
            <h2>Atención inmediata</h2>
            <button>
              <div class="icono">
                <img :src="$cdn+'/imagenes/Icon-hi-normal.svg'" alt="">
              </div>
            </button>
        </section>-->
        <section class="contenedor-opciones">
            <h3>Bienvenido, comunicate con nosotros fácil y rápido.</h3>
            <!-- <p>Elige una opción de servicio:</p> -->
            <!-- <button class="boton-opcion" v-on:click="irOpcion1()">
              <img :src="$cdn+'/imagenes/Icon-service-1.svg'" alt="">
              CHAT POR WHATSAPP
            </button> -->
            <!--<button class="boton-opcion" v-on:click="irOpcion2()">
              <img :src="$cdn+'/imagenes/Icon-service-2.svg'" alt="">
              CHAT EN LÍNEA
            </button>-->
            <button class="boton-opcion" v-on:click="irOpcion3()">
              <img :src="$cdn+'/imagenes/Icon-service-2.svg'" alt="">
              ENVIAR SOLICITUD
            </button>
        </section>
    </div>
</template>

<script>

export default {
  name: 'opcionesAtencion',
  components: {
  },
  mounted() {
    let html = "<script src='https://cdnjs.cloudflare.com/ajax/libs/socket.io/1.7.3/socket.io.min.js' ><\/script>"
    $('head').append(html)
  },
  methods: {
    irOpcion1: function () {
      this.$emit('irOpcion1')
    },
    irOpcion2: function () {
      this.$emit('irOpcion2')
    },
    irOpcion3: function () {
      this.$emit('irOpcion3')
    }
  }
}
</script>

<style lang="scss">
.contenedor-boton {
    max-width: max-content;
    margin: 30px 65px;
    display: flex;
    align-items: center;
    position: relative;

    h2 {
      width: max-content;
      height: 45px;
      margin: 0;
      padding: 0 50px 0 20px;
      border-radius: 23px;
      background-color: #FFFFFF;
      box-shadow: 0px 3px 6px #00000033;
      font-family: 'Roboto', sans-serif;
      font-size: 15px;
      font-weight: normal;
      color: #4D556E;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button {
      width: 66px;
      height: 66px;
      border: 0;
      border-radius: 50%;
      background-color: #1C78E6;
      position: absolute;
      right: -33px;
      transition: all 0.3s;

      .icono {
        width: 53px;
        height: 53px;
        margin: 0;

        img {
          width: 53px;
          height: 53px;
          margin: 0;
        }
      }
    }
}

.contenedor-opciones {
    width: 300px;
    margin: 0 auto;
    padding-top: 20px;
    border-top: 1px solid #C5C9D5;

    h3 {
        margin-bottom: 10px;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        font-weight: 500;
        color: #4D556E;
        text-align: center;
    }

    p {
        margin-bottom: 20px;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-weight: normal;
        color: #4D556E;
        text-align: center;
    }

    .boton-opcion {
        width: calc(100% - 30px);
        height: 70px;
        margin: 0 auto 20px auto;
        padding: 0 22px;
        border: 0;
        border-radius: 30px;
        background-color: #1C78E6;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-weight: bold;
        color: #FFFFFF;
        text-align: left;
        text-transform: uppercase;
        text-shadow: 0px 2px 2px #00000015;
        box-shadow: 0px 3px 6px #00000029;
        display: flex;
        align-items: center;

        img {
          width: 32px;
          height: 32px;
          margin-right: 15px;
        }
    }
}
</style>
