<template>
  <div class="contenedor-chat" id="contenedorChat">
    <header>
      <button v-on:click="activarBoton(section)">
        <img :src="$cdn+'/imagenes/Icon-top-var-back.svg'" alt="">
        <template v-if="section == 'inicio'">Cerrar</template>
        <template v-else>Volver</template>
      </button>
      <!-- <img :src="$cdn+'/imagenes/Logo-simbolo-cajasan-azul@2x.png'" alt=""> -->
    </header>
    <opcionesAtencion v-if="seccionActiva == 'opcionesIniciales'" @irOpcion1="irOpcion1" @irOpcion2="irOpcion2" @irOpcion3="irOpcion3"/>
    <!-- <chatWhatsapp v-else-if="seccionActiva == 'opcion1'"/> -->
    <chatEnLinea v-else-if="seccionActiva == 'opcion2'"/>
    <chatEnviarSolicitud v-else-if="seccionActiva == 'opcion3'"/>
    <footer>
      <!-- <a href="https://likeparrot.com/"><img :src="$cdn+'/imagenes/Powered-likeparrot.svg'" alt=""></a> -->
      <a href="https://pradek.co/"><img height="20px" :src="$cdn+'/imagenes/Logo-Pradek-2021-vector.svg'" alt=""></a>
    </footer>
  </div>
</template>

<script>
import opcionesAtencion from '@/components/chat/opcionesAtencion.vue'
import chatWhatsapp from '@/components/chat/opcionWhatsapp/chatWhatsapp.vue'
import chatEnLinea from '@/components/chat/opcionesChatEnLinea/chatEnLinea.vue'
import chatEnviarSolicitud from '@/components/chat/opcionEnviarSolicitud/chatEnviarSolicitud.vue'

export default {
  name: 'contenedorChat',
  components: {
    opcionesAtencion,
    chatWhatsapp,
    chatEnLinea,
    chatEnviarSolicitud
  },
  data () {
    return {
      seccionActiva: 'opcionesIniciales',
      section: 'inicio'
    }
  },
  methods: {
    activarBoton: function (section) {
      console.log("Seccion "+section)
      if (section == 'inicio') {
        this.$emit('activarBoton')
      } else {
        if(this.seccionActiva=='opcion2'){
          if(confirm("Está seguro de terminar la atención en línea")){
            if(socket != undefined){
              try{
                socket.emit('disconnect')
              }catch (e) {
                console.log(e)
              }
            }

          }
        }
        this.seccionActiva = 'opcionesIniciales'
        this.section = 'inicio'
      }
    },
    irOpcion1: function () {
      this.seccionActiva = 'opcion1'
      this.section = 'opcion'
    },
    irOpcion2: function () {
      this.seccionActiva = 'opcion2'
      this.section = 'opcion'
    },
    irOpcion3: function () {
      this.seccionActiva = 'opcion3'
      this.section = 'opcion'
    }
  }
}
</script>

<style lang="scss">
.contenedor-base {
  height: 100vh;
  background-image: url('../../assets/imagenes/Background-banner.jpg');
}

.contenedor-chat {
  width: 365px;
  height: calc(95vh);/*calc(100vh - 60px);*/
  min-height: 500px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 3px 3px 8px #00000014;
  overflow: hidden;
  position: fixed;
  top: 20px;
  right: 20px;
  bottom: 35px;
  z-index: 1000000;
  header {
    width: 100%;
    height: 50px;
    padding: 0 30px;
    background-color: #F5F6F7;
    display: flex;
    align-items: center;

    button {
      width: max-content;
      border: 0;
      background: transparent;
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      font-weight: 500;
      color: #4D556E;
      text-align: left;
      display: flex;
      align-items: center;

      img {
        width: 18px;
        height: auto;
        margin-right: 10px;
      }
    }

    img {
      width: 24px;
      height: 24px;
      margin-left: auto;
    }
  }

  & > div {
    max-height: calc(90vh - 74px);
    overflow: auto;
  }

  footer {
    width: 100%;
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;

    p {
      margin: 0;
      font-family: 'Roboto', sans-serif;
      font-size: 10px;
      font-weight: normal;
      color: #6D7073;
      text-align: left;

      a {
        color: #3B3B3B;
      }
    }
  }
}
</style>
