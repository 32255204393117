<template>
  <div class="home">
    <botonAtencion v-if="seccionActiva == 'botonChat'"  @activarChat="activarChat"/>
    <contenedorChat v-else-if="seccionActiva == 'contenedorChat'"  @activarBoton="activarBoton"/>
  </div>
</template>

<script>
import botonAtencion from '@/components/chat/botonAtencion.vue'
import contenedorChat from '@/components/chat/contenedorChat.vue'

export default {
  name: 'Home',
  components: {
    botonAtencion,
    contenedorChat
  },
  data () {
    return {
      seccionActiva: 'botonChat'
    }
  },
  methods: {
    activarChat: function () {
      this.seccionActiva = 'contenedorChat'
    },
    activarBoton: function () {
      this.seccionActiva = 'botonChat'
    }
  }
}
</script>
