<template>
  <section style="position: relative">
    <!--<div class="fecha-inicio">
        <h3>CHAT EN LÍNEA 16 de ENERO</h3>
    </div>
    <div class="mensaje-recibido">
      <div class="mensaje">
        <p>¡Hola! Has elegido saber más sobre <strong>SUBSIDIO FAMILIAR DE VIVIENDA.</strong></p>
        <p>Entre las siguientes opciones, elige la que más sea de tu interés y escribe su número:</p>
        <p>
          1. Postulaciones <br>
          2. Modalidades <br>
          3. Hogares Unipersonales <br>
          4. Programas del Gobierno <br>
          5. Beneficiarios <br>
          6. ABC de Vivienda <br>
        </p>
        <span class="fecha">10:00 a.m.</span>
      </div>
      <div class="icono">
        <img src="@/assets/imagenes/Icon-chat-bot.svg" alt="">
      </div>
    </div>
    <div class="mensaje-enviado">
      <div class="icono">
        <img src="@/assets/imagenes/Icon-chat-user.svg" alt="">
      </div>
      <div class="mensaje">
        <p>1.</p>
        <span class="fecha">10:10 a.m.</span>
      </div>
    </div>
    <div class="mensaje-recibido">
      <div class="mensaje">
        <p>Puede postularse del 16 de enero al 8 de marzo, en nuestro portal web.</p>
        <span class="fecha">10:15 a.m.</span>
      </div>
      <div class="icono">
        <img src="@/assets/imagenes/Icon-chat-bot.svg" alt="">
      </div>
    </div>-->
    <div id="mensajes_chatter_window" class="contenedor-mensaje">
      <!--Mensajes de conversación-->
    </div>

      <div class="contenedor-input">
        <div class="input">
          <input type="text" id="mensaje_enviar" placeholder="Escribir mensaje" v-on:keyup.enter="enviarmsg">
          <img src="@/assets/imagenes/Icon-send-message-normal.svg" alt="" @click="enviarmsg">
          <img src="@/assets/imagenes/Icon-send-message-hover.svg" alt="" @click="enviarmsg">
        </div>
      </div>

  </section>
</template>

<script>

export default {
  name: 'chatActivo',
  components: {
  },
  mounted() {
  },
  methods:{
    enviarmsg: function (){
      let user_email = chatter_user_email // ORIGEN DEL MENSAJE
      let user_name = chatter_user_name // ORIGEN DEL MENSAJE
      let user_input = $('#mensaje_enviar').val() // CONTENIDO DEL MENSAJE EN EL IMPUNT DE INTERACCIÓN
      let agente_email = chatter_agente_email // EMAIL AGENTE SELECCIONADO
      let agente_sid =  chatter_agente_sid // SID DEL AGENTE SELECCIONADO
      let user_avatar = chatter_user_avatar // AVATAR DEL USUARIO
      //e.preventDefault()
      if( user_email != undefined && user_input != undefined && user_input != ""){
        console.log("Enviando el mensaje.")
        user_input = btoa(user_input)
        console.log(user_input)
        active_user = user_email
        // NOS UNIMOS A LA SALA
        socket.emit('send private', {
          user_name : user_name,
          user_email : user_email,
          user_to : agente_email,
          user_avatar: user_avatar,
          message : user_input,
          room: agente_sid,
          tipo: 'normal'
        })
        $('#mensaje_enviar').val('').focus()

        console.log("CHATTER - MENSAJE ENVIADO")
      }
      else
        console.log("Se ha generado un error al enviar el mensaje.")
    }
  }
}
</script>

<style lang="scss">
/*.fecha-inicio {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    width: max-content;
    height: 30px;
    padding: 0 20px;
    border-radius: 30px;
    background-color: #1C78E6;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    text-shadow: 0px 2px 2px #00000015;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.received_msg {
  margin-bottom: 20px;
  padding: 0 20px;
  display: flex;
  align-items: center;

  .received_withd_msg {
    width: 100%;
    margin-right: 10px;
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #F5F6F7;
    position: relative;

    p {
      margin: 0;
      margin-bottom: 10px;
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      font-weight: normal;
      color: #4D556E;
      text-align: left;
    }

    .time_date  {
      margin: 0;
      font-family: 'Roboto', sans-serif;
      font-size: 10px;
      font-weight: normal;
      color: #4D556E;
      text-align: left;
      position: absolute;
      right: 15px;
      bottom: 5px;
    }

    .small  {
      margin: 0;
      font-family: 'Roboto', sans-serif;
      font-size: 10px;
      font-weight: normal;
      color: #4D556E;
      text-align: right;
      right: 15px;
      bottom: 5px;
    }
  }

  .chatter_avatar {
    min-width: 24px;
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
}

.mensaje-enviado {
  margin-bottom: 20px;
  padding: 0 20px;
  display: flex;
  align-items: center;

  .mensaje {
    width: 100%;
    margin-left: 10px;
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #878FA7;
    position: relative;

    p {
      margin: 0;
      margin-bottom: 10px;
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      font-weight: normal;
      color: #FFFFFF;
      text-align: left;
    }

    .fecha  {
      margin: 0;
      font-family: 'Roboto', sans-serif;
      font-size: 10px;
      font-weight: normal;
      color: #FFFFFF;
      text-align: left;
      position: absolute;
      right: 15px;
      bottom: 5px;
    }
  }

  .icono {
    min-width: 24px;
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
}
*/
.contenedor-input {
  padding: 10px 20px;
  background-color: #FFFFFF;
  position: sticky;
  bottom: -1px;

  .input {
    display: flex;
    align-items: center;
    position: relative;

    input {
      width: 100%;
      height: 45px;
      padding: 0 20px 0 30px;
      border: 0px;
      border-radius: 23px;
      background-color: #F5F6F7;
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      font-weight: normal;
      color: #878FA7;
      text-align: left;
    }

    img {
      width: 15px;
      height: auto;
      position: absolute;
      top: 15px;
      right: 15px;
    }

    & > :last-child {
      display: none;
    }

    &:hover {
      input {
        color: #4D556E;
      }

      & > :nth-child(2) {
        display: none;
      }

      & > :last-child {
        display: block;
      }
    }
  }
}
.contenedor-mensaje{
  /*min-height: 400px;
  max-height: 400px;*/
  height: calc(81vh - 100px);
  overflow-y: auto;
}
</style>
