import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

const apiAxios = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})

Vue.use(Vuex, apiAxios)

export default new Vuex.Store({
  state: {
  },

  mutations: {
  },

  actions: {
    fetchCreateTicket: function ({ commit }, payload) {
      let response = axios.post('cliente_ticket_crear', payload).then((response) => {
        let data = response.data;
        return data;
      })
      .catch((error) => {
        let data = {};
        data.CODIGO = '3';
        data.MESAJE = 'Fallo al crear el ticket';
        data.DATOS = '';
        return data;
      })

      return response;
    },
  },

  getters: {
  }
})
