<template>
  <div>
    <section class="titulo-seccion">
      <h2>
        <img src="@/assets/imagenes/Icon-service-2.svg" alt="">
        ENVIAR SOLICITUD
      </h2>
    </section>

    <formulario :ticket="ticket" v-if="seccionActiva == 'formulario'" @irConfirmacion="irConfirmacion"/>
    <confirmacion v-else-if="seccionActiva == 'confirmacion'"/>
  </div>
</template>

<script>
import formulario from '@/components/chat/opcionEnviarSolicitud/formulario.vue'
import confirmacion from '@/components/chat/opcionEnviarSolicitud/confirmacion.vue'

export default {
  name: 'chatEnLinea',

  components: {
    formulario,
    confirmacion
  },

  data () {
    return {
      seccionActiva: 'formulario',
      ticket: ''
    }
  },

  methods: {
    irConfirmacion: function (ticket) {
      console.log(typeof ticket)
      console.log(ticket);
      this.seccionActiva = 'confirmacion';
      this.ticket = ticket;
    }
  }
}
</script>

<style lang="scss" scoped>
.titulo-seccion {
  padding: 30px 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    width: 100%;
    height: 60px;
    padding: 0 22px;
    border: 0;
    border-radius: 30px;
    background-color: #1C78E6;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: left;
    text-transform: uppercase;
    text-shadow: 0px 2px 2px #00000015;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
      margin-right: 15px;
    }
  }
}
</style>
