<template>
    <div class="contenedor-boton-atencion" id="botonChat">
        <h2>Comunicate con nosotros.</h2>
        <button v-on:click="activarChat()">
          <div class="icono">
            <img :src="$cdn+'/imagenes/Icon-hi-normal.svg'" alt="">
            <img :src="$cdn+'/imagenes/Icon-hi-hover.svg'" alt="">
          </div>
        </button>
    </div>
</template>

<script>

export default {
  name: 'botonChat',
  components: {
  },
  methods: {
    activarChat: function () {
      this.$emit('activarChat')
    }
  }
}
</script>

<style lang="scss">
.contenedor-boton-atencion {
  max-width: 300px;
  display: flex;
  align-items: center;
  position: fixed;
  right: 72px;
  bottom: 35px;
  z-index: 1000000;
  h2 {
    width: max-content;
    height: 45px;
    margin: 0;
    padding: 0 50px 0 20px;
    border-radius: 23px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000033;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: normal;
    color: #4D556E;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    width: 66px;
    height: 66px;
    border: 0;
    border-radius: 50%;
    background-color: #1C78E6;
    position: absolute;
    right: -33px;
    transition: all 0.3s;

    .icono {
      width: 53px;
      height: 53px;
      margin: 0;

      img {
        width: 53px;
        height: 53px;
        margin: 0;
      }

      & > :nth-child(2) {
        display: none;
      }
    }

    &:hover {
      width: 82px;
      height: 82px;
      padding: 0 14px;
      right: -41px;

      .icono {
        & > :nth-child(1) {
          display: none;
        }

        & > :nth-child(2) {
          display: block;
        }
      }
    }
  }
}
</style>
