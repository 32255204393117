<template>
    <section class="contenedor-formulario">
        <p>Completa la siguiente información para iniciar chat</p>
      <input id="inputName" type="text" placeholder="Nombre completo" required>
      <input id="inputEmail" type="email" placeholder="Email" required>
      <label for="tema">Tema de interés</label>
      <select name="tema" id="tema">
          <option value="1">Crédito</option>
          <option value="2">Salud</option>
          <option value="3">Afiliación</option>
          <option value="4">Cursos</option>
          <option value="5">Empleabilidad y subsidio al desempleo</option>
          <option value="6">Soporte portal web</option>
          <option value="7">Hotel Guarigua</option>
          <option value="8">Recreación</option>
          <option value="9">Cuota monetaria y tarjeta multiservicios</option>
          <option value="10">Vivienda</option>
      </select>
        <input type="number" placeholder="Número de identificación" required>
        <input type="number" placeholder="Número de celular" required>
        <div class="terminos">
            <input type="checkbox" id="chterminoschat" v-model="chcondiciones">
            <p>¿Autoriza usted a Cajasan el tratamiento de sus datos personales, según lo contemplado en la ley 1581 del 2012 y conforme a la política de tratamiento de la Información de Cajasan? <br> <a href="https://www.cajasan.com/nosotros/proteccion-de-datos1" target="_blank">Política de tratamiento de datos personales</a></p>
        </div>
        <p v-if="showmsg" style="color: red">{{msg}}</p>
        <button v-on:click="irChatActivo()">INICIAR</button>
    </section>
</template>

<script>

export default {
  name: 'formularioEnLinea',
  data(){
    return {
      chcondiciones:false,
      showmsg:false,
      msg:''
    }
  },
  components: {
  },
  mounted() {
    var fechaEnMiliseg = Date.now();
    let html =""
      /*let html = "<script src='https://cdnjs.cloudflare.com/ajax/libs/socket.io/1.7.3/socket.io.min.js' ><\/script>"
      $('head').append(html)*/
      html = '<link href="'+this.$cdn+'static/chat.css?'+fechaEnMiliseg+'" rel="stylesheet"><\/link>'
      $('head').append(html)
      html = '<script src="'+this.$cdn+'static/chatbox.js?'+fechaEnMiliseg+'"><\/script>'
      $('head').append(html)
      html = '<script src="'+this.$cdn+'static/sound.js?'+fechaEnMiliseg+'"><\/script>'
      $('head').append(html)
      html = '<script src="'+this.$cdn+'static/chatter.base.js?'+fechaEnMiliseg+'"><\/script>'
      $('head').append(html)
      html = '<script src="'+this.$cdn+'static/chatter.live.js?'+fechaEnMiliseg+'"><\/script>'
      $('head').append(html)
  },
  methods: {
    irChatActivo: function () {
      let ok=true
      this.msg=''
      if($('#inputName').val()=='' || $('#inputEmail').val()==''){
        ok=false
        this.showmsg=true
        this.msg='Por favor llenar todos los campos.'
      }
      if(!this.chcondiciones){
        this.showmsg=true
        this.msg=this.msg+'Para iniciar la conversación debes aceptar el tratamiento de datos personales'
        ok=false
      }
      if(ok){
        this.showmsg=false
        chatter_live_iniciar()
        this.$emit('irChatActivo')
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.contenedor-formulario {
    width: 300px;
    margin: 0 auto;
    padding-top: 20px;
    border-top: 1px solid #C5C9D5;

    > p {
        margin-bottom: 20px;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-weight: normal;
        color: #4D556E;
        text-align: center;
    }

    > label {
        width: 100%;
        margin-bottom: 5px;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-weight: normal;
        color: #4D556E;
        text-align: left;
    }

    > input,
    select {
        width: 100%;
        height: 45px;
        margin-bottom: 5px;
        padding: 0 20px;
        border: 0;
        border-radius: 5px;
        background-color: #F5F6F7;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-weight: normal;
        color: #878FA7;
        text-align: left;
        text-transform: uppercase;
    }

    .terminos {
        margin-top: 15px;
        display: flex;
        align-items: center;

        input {
            margin-right: 10px;
        }

        p {
            margin: 0;
            font-family: 'Roboto', sans-serif;
            font-size: 13px;
            font-weight: normal;
            color: #4D556E;
            text-align: left;

            a {
                color: #1C78E6;
            }
        }
    }

    button {
        width: 100%;
        height: 45px;
        margin-top: 20px;
        border: 0;
        border-radius: 5px;
        background-color: #6F778E;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-weight: normal;
        color: #FFFFFF;
        text-align: center;
        text-transform: uppercase;
        transition: all 0.5s;

        &:hover {
            background-color: #4D556E;
        }
    }
}
</style>
