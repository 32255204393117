<template>
  <section class="contenedor-formulario">
    <p>Completa la siguiente información para enviar una solicitud</p>

    <input type="text" placeholder="Nombre completo" :class="className ? '' : 'invalid'" v-model="txtName">
    <div class="feedback" v-if="feedbackName">
      <p>{{ feedbackName }}</p>
    </div>

    <input type="email" placeholder="Email" :class="classEmail ? '' : 'invalid'" v-model="txtEmail">
    <div class="feedback" v-if="feedbackEmail">
      <p>{{ feedbackEmail }}</p>
    </div>

    <input type="number" placeholder="Número de celular" :class="classPhone ? '' : 'invalid'" v-model="txtPhone">
    <div class="feedback" v-if="feedbackPhone">
      <p>{{ feedbackPhone }}</p>
    </div>

    <!-- <select name="cliente" id="cliente">
      <option disabled selected>Cliente tipo</option>
      <option value="1">Persona natural</option>
      <option value="2">Persona jurídica</option>
    </select>

    <select name="area" id="area">
      <option disabled selected>Área</option>
      <option value="1">Soporte</option>
    </select>

    <select name="gestor" id="gestor">
      <option disabled selected>Gestor</option>
      <option value="1">Soporte</option>
    </select>

    <select name="asunto" id="asunto">
      <option disabled selected>Asunto</option>
      <option value="1">Ayuda con instalación de software</option>
      <option value="2">Sincronización de mis archivos con la plataforma</option>
      <option value="3">Otro</option>
    </select>

    <select name="prioridad" id="prioridad">
      <option disabled selected>Prioridad</option>
      <option value="1">Baja</option>
      <option value="2">Media</option>
      <option value="3">Alta</option>
    </select>

    <select name="canal" id="canal">
      <option disabled selected>Canal origen</option>
      <option value="1">E-mail</option>
      <option value="2">Whatsapp</option>
      <option value="3">Likeparrot</option>
      <option value="4">Telegram</option>
      <option value="5">Facebook</option>
      <option value="6">Teléfono</option>
      <option value="7">Correspondencia</option>
      <option value="8">Otro</option>
    </select> -->

    <textarea cols="30" rows="5" placeholder="Escribe aquí tu mensaje" :class="classDescription ? '' : 'invalid'" v-model="txtDescription"></textarea>
    <div class="feedback" v-if="feedbackDescription">
      <p>{{ feedbackDescription }}</p>
    </div>

    <div class="terminos">
      <input type="checkbox" :class="classTerms ? '' : 'invalid'" v-model="cbxTerms">
      <p>Aceptar <br> <a href="">Política de tratamiento de datos personales</a></p>
    </div>
    <div class="feedback" v-if="feedbackTerms">
      <p>{{ feedbackTerms }}</p>
    </div>

    <button @click="send()">ENVIAR</button>
  </section>
</template>

<script>
export default {
  name: 'formulario',

  data () {
    return {
      txtName: '',
      className: true,
      feedbackName: '',

      txtEmail: '',
      classEmail: true,
      feedbackEmail: '',

      txtPhone: '',
      classPhone: true,
      feedbackPhone: '',

      txtDescription: '',
      classDescription: true,
      feedbackDescription: '',

      cbxTerms: false,
      classTerms: true,
      feedbackTerms: ''
    }
  },

  methods: {
    send: function () {
      let valid = this.validate();

      if (valid) {
        var formData = new FormData();
        formData.append('nombre_cliente', this.txtName);
        formData.append('email_cliente', this.txtEmail);
        formData.append('telefono_cliente', this.txtPhone);
        formData.append('descripcion', this.txtDescription);
        formData.append('api_client', clienteid);

        this.$store.dispatch('fetchCreateTicket', formData).then((result) => {
          console.log(result);
          if (result.CODIGO == 1) {
            this.clean();
            this.$emit('irConfirmacion', result.DATOS.toString());
          } else {
            this.$emit('irConfirmacion', result.MENSAJE);
          }
        })
      }
    },

    validate: function () {
      let valid = true;

      if (this.txtName.toString().trim() == '') {
        this.className = false;
        this.feedbackName = 'Error, el campo se encuentra vacío';
        valid = false;
      } else if (this.txtName.length > 80) {
        this.className = false;
        this.feedbackName = 'Error, el nombre debe tener un máximo de 80 caracteres';
        valid = false;
      } else {
        this.className = true;
        this.feedbackName = '';
      }

      if (this.txtEmail.toString().trim() == '') {
        this.classEmail = false;
        this.feedbackEmail = 'Error, el campo se encuentra vacío';
        valid = false;
      } else if (!this.validateEmail(this.txtEmail)) {
        this.classEmail = false;
        this.feedbackEmail = 'Error, no se ingreso un correo';
        valid = false;
      } else {
        this.classEmail = true;
        this.feedbackEmail = '';
      }

      if (this.txtPhone == '') {
        this.classPhone = false;
        this.feedbackPhone = 'Error, el campo se encuentra vacío';
        valid = false;
      } else if (this.txtPhone.length > 10) {
        this.classPhone = false;
        this.feedbackPhone = 'Error, el nombre debe tener un máximo de 10 caracteres';
        valid = false;
      } else {
        this.classPhone = true;
        this.feedbackPhone = ''
      }

      if (this.txtDescription == '') {
        this.classDescription = false;
        this.feedbackDescription = 'Error, el campo se encuentra vacío';
        valid = false;
      } else if (this.txtDescription.length > 500) {
        this.classDescription = false;
        this.feedbackDescription = 'Error, el nombre debe tener un máximo de 500 caracteres';
        valid = false;
      } else {
        this.classDescription = true;
        this.feedbackDescription = '';
      }

      if (this.cbxTerms == false) {
        this.classTerms = false;
        this.feedbackTerms = 'Para enviar la solicitud debes aceptar el tratamiento de datos personales';
        valid = false;
      } else {
        this.classTerms = true;
        this.feedbackTerms = '';
      }

      return valid;
    },

    validateEmail: function (email) {
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    validateIntegers: function (evt) {
      let keyCode = evt.keyCode ? evt.keyCode : evt.which;

      if (keyCode < 48 || keyCode > 57) {
        evt.preventDefault();
      }
    },

    clean: function () {
      this.txtName = '';
      this.className = true;
      this.feedbackName = '';

      this.txtEmail = '';
      this.classEmail = true;
      this.feedbackEmail = '';

      this.txtPhone = '';
      this.classPhone = true;
      this.feedbackPhone = '';

      this.txtDescription = '';
      this.classDescription = true;
      this.feedbackDescription = '';

      this.cbxTerms = false;
      this.classTerms = true;
      this.feedbackTerms = '';
    }
  }
}
</script>

<style lang="scss" scoped>
.contenedor-formulario {
  width: 300px;
  margin: 0 auto;
  padding-top: 20px;
  border-top: 1px solid #C5C9D5;

  > p {
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: normal;
    color: #4D556E;
    text-align: center;
  }

  > input,
  > select {
    width: 100%;
    height: 45px;
    margin-bottom: 5px;
    padding: 0 20px;
    border: 0;
    border-radius: 5px;
    background-color: #F5F6F7;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: normal;
    color: #878FA7;
    text-align: left;
    text-transform: uppercase;
  }

  textarea {
    width: 100%;
    height: auto;
    margin-bottom: 5px;
    padding: 15px 20px;
    border: 0;
    border-radius: 5px;
    background-color: #F5F6F7;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: normal;
    color: #878FA7;
    text-align: left;
    text-transform: uppercase;
  }

  .terminos {
    margin-top: 15px;
    display: flex;
    align-items: center;

    input {
      margin-right: 10px;
    }

    p {
      margin: 0;
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      font-weight: normal;
      color: #4D556E;
      text-align: left;

      a {
        color: #1C78E6;
      }
    }
  }

  button {
    width: 100%;
    height: 45px;
    margin-top: 20px;
    border: 0;
    border-radius: 5px;
    background-color: #6F778E;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: normal;
    color: #FFFFFF;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.5s;

    &:hover {
      background-color: #4D556E;
    }
  }

  .feedback {
    padding: 10px 0;
    display: flex;
    align-items: center;

    p {
      margin: 0;
      font-family: 'Mulish', sans-serif;
      font-size: 12px;
      font-weight: normal;
      color: #F37290;
      text-align: left;
    }
  }

  .invalid {
    border: 1px solid #F37290 !important;
    color: #F37290 !important;
  }
}
</style>
