// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/imagenes/Background-banner.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".contenedor-base{height:100vh;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.contenedor-chat{width:365px;height:95vh;min-height:500px;border-radius:10px;background-color:#fff;box-shadow:3px 3px 8px rgba(0,0,0,.0784313725490196);overflow:hidden;position:fixed;top:20px;right:20px;bottom:35px;z-index:1000000}.contenedor-chat header{width:100%;height:50px;padding:0 30px;background-color:#f5f6f7;display:flex;align-items:center}.contenedor-chat header button{width:-webkit-max-content;width:-moz-max-content;width:max-content;border:0;background:transparent;font-family:Roboto,sans-serif;font-size:13px;font-weight:500;color:#4d556e;text-align:left;display:flex;align-items:center}.contenedor-chat header button img{width:18px;height:auto;margin-right:10px}.contenedor-chat header img{width:24px;height:24px;margin-left:auto}.contenedor-chat>div{max-height:calc(90vh - 74px);overflow:auto}.contenedor-chat footer{width:100%;margin:15px 0;display:flex;align-items:center;justify-content:center;position:absolute;bottom:0;left:0}.contenedor-chat footer p{margin:0;font-family:Roboto,sans-serif;font-size:10px;font-weight:400;color:#6d7073;text-align:left}.contenedor-chat footer p a{color:#3b3b3b}", ""]);
// Exports
module.exports = exports;
