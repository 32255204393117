<template>
    <div>
        <section class="titulo-seccion">
            <h2>
                <img :src="$cdn+'/imagenes/Icon-service-1.svg'" alt="">
                CHAT POR WHATSAPP
            </h2>
        </section>
        <formulario v-if="seccionActiva == 'formulario'"  @irConfirmacion="irConfirmacion"/>
        <confirmacion v-else-if="seccionActiva == 'confirmacion'"/>
    </div>
</template>

<script>
import formulario from '@/components/chat/opcionWhatsapp/formulario.vue'
import confirmacion from '@/components/chat/opcionWhatsapp/confirmacion.vue'

export default {
  name: 'chatWhatsapp',
  components: {
    formulario,
    confirmacion
  },
  data () {
    return {
      seccionActiva: 'formulario'
    }
  },
  methods: {
    irConfirmacion: function () {
      this.seccionActiva = 'confirmacion'
    }
  }
}
</script>

<style lang="scss" scoped>
.titulo-seccion {
    padding: 30px 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
        width: 100%;
        height: 60px;
        padding: 0 22px;
        border: 0;
        border-radius: 30px;
        background-color: #1C78E6;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-weight: bold;
        color: #FFFFFF;
        text-align: left;
        text-transform: uppercase;
        text-shadow: 0px 2px 2px #00000015;
        box-shadow: 0px 3px 6px #00000029;
        display: flex;
        align-items: center;

        img {
          width: 32px;
          height: 32px;
          margin-right: 15px;
        }
    }
}
</style>
