<template>
    <section class="contenedor-formulario">
        <p>Completa la siguiente información para iniciar chat</p>
        <input id="nombre"  name="nombre" type="text" placeholder="Nombre completo" v-model="nombre" required>
        <input id="movil" name="movil" type="number" placeholder="Número de celular" v-model="movil" required>
        <input id="email" name="email" type="email" placeholder="Email" v-model="email" required>
        <div class="terminos">
          <input type="checkbox" v-model="chcondiciones">
          <p>¿Autoriza usted a Cajasan el tratamiento de sus datos personales, según lo contemplado en la ley 1581 del 2012 y conforme a la política de tratamiento de la Información de Cajasan? <br> <a href="https://www.cajasan.com/nosotros/proteccion-de-datos1" target="_blank">Política de tratamiento de datos personales</a></p>
        </div>
        <section v-if="showmsg" class="mensaje">
          <p>{{msg}}</p>
        </section>
        <button type="submit" v-on:click="irConfirmacion()">INICIAR</button>
        <input type="hidden" class="form-control" id="cliente_id" name="cliente_id" v-model="cliente_id">
        <input type="hidden" class="form-control" id="wnumero" name="wnumero" v-model="wnumero">
    </section>
</template>

<script>
import axios from 'axios'
export default {
  name: 'formularioWhatsapp',
  data(){
    return{
      urlwabot:'https://wabot.pradek.co/what_iniciar',
      nombre: '',
      movil: '',
      email: '',
      cliente_id: '1',
      wnumero: '16474965603',
      chcondiciones:false,
      showmsg:false,
      msg:''
    }
  },
  components: {
  },
  methods: {
    irConfirmacion: function () {
      console.log("Acepto "+this.chcondiciones)
      let ok = true;
      this.msg=''
      if(this.email=="" || this.movil=="" || this.nombre==""){
        this.msg='Por favor llenar todos los campos.'
        ok=false
      }
      if(!this.chcondiciones){
        this.msg=this.msg+'Para iniciar la conversación debes aceptar el tratamiento de datos personales'
        ok=false
      }
      if(ok){
        this.showmsg=false
        let datos = {
          nombre: this.nombre,
          movil: this.movil,
          email: this.email,
          cliente_id: this.cliente_id,
          wnumero: this.wnumero
        }
        axios.post(this.urlwabot, datos).then(result=>{
          let t = result.data
          if(t=='True'){
            this.$emit('irConfirmacion')
          }
        })
      }else{
        this.showmsg=true;
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.contenedor-formulario {
    width: 300px;
    margin: 0 auto;
    padding-top: 20px;
    border-top: 1px solid #C5C9D5;

    > p {
        margin-bottom: 20px;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-weight: normal;
        color: #4D556E;
        text-align: center;
    }

    > input {
        width: 100%;
        height: 45px;
        margin-bottom: 5px;
        padding: 0 20px;
        border: 0;
        border-radius: 5px;
        background-color: #F5F6F7;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-weight: normal;
        color: #878FA7;
        text-align: left;
        text-transform: uppercase;
    }

    .terminos {
        margin-top: 15px;
        display: flex;
        align-items: center;

        input {
            margin-right: 10px;
        }

        p {
            margin: 0;
            font-family: 'Roboto', sans-serif;
            font-size: 13px;
            font-weight: normal;
            color: #4D556E;
            text-align: left;

            a {
                color: #1C78E6;
            }
        }
    }

    button {
        width: 100%;
        height: 45px;
        margin-top: 20px;
        border: 0;
        border-radius: 5px;
        background-color: #6F778E;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-weight: normal;
        color: #FFFFFF;
        text-align: center;
        text-transform: uppercase;
        transition: all 0.5s;

        &:hover {
            background-color: #4D556E;
        }
    }
}
.mensaje{
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #f36041;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
