<template>
    <section class="contenedor-confirmacion">
        <p>Revisa tu celular y empecemos a chatear!</p>
    </section>
</template>

<script>

export default {
  name: 'confirmacionWhatsapp',
  components: {
  }
}
</script>

<style lang="scss" scoped>
.contenedor-confirmacion {
    width: 300px;
    margin: 0 auto;
    padding-top: 20px;
    border-top: 1px solid #C5C9D5;

    > p {
        height: 45px;
        border-radius: 5px;
        background-color: #EBFFE0;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-weight: normal;
        color: #4D556E;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
